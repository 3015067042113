<template>
	<div>
		<!-- 头部logo -->
		<moveHeadLogo @navigation="navigation"></moveHeadLogo>
		
		<!-- 头部导航 -->
		<moveNavigation :headTitle="headTitle" :headImg="headImg"></moveNavigation>
		
		<div class="values">
			<el-row class="values-title">
				{{list.title}}
			</el-row>
			<el-row class="values-message" v-html="list.bodyContent">
				{{list.bodyContent}}
			</el-row>
		</div>
		
		<!-- 菜单导航 -->
		<moveMeauDialog :meauValue="navigationvalue" @meauFn="menu"></moveMeauDialog>
		
		<!-- 底部导航 -->
		<moveBottomNavigation></moveBottomNavigation>
	</div>
</template>

<script>
	// 图片查询接口
	import { selectPictureListById , selectContentListById } from '@/api/index.js'

	// 头部组件
	import moveHeadLogo from '@/components/move/moveHeadLogo.vue'
	// 菜单导航
	import moveMeauDialog from '@/components/move/moveMeauDialog.vue'
	// 头部导航
	import moveNavigation from '@/components/move/moveNavigation.vue'
	// 底部导航
	import moveBottomNavigation from '@/components/move/moveBottomNavigation.vue'
	export default
	{
		data()
		{
			return{
				// 头部导航标题
				headTitle:'VALUES',
				// 头部导航图片
				headImg:'/index/knowledge.png',
				// 导航值
				navigationvalue:false,
				list:{}
			}
		},
		components:
		{
			moveHeadLogo,
			moveNavigation,
			moveBottomNavigation,
			moveMeauDialog,
		},
		created()
		{
			this.getValueList();
		},
		methods:
		{
			// 头部传来的数据
			navigation(v)
			{
				this.navigationvalue=v;
				console.log(this.navigationvalue,'头部传递菜单导航')
			},
			// 遮罩层传回来的数据
			menu(v)
			{
				this.navigationvalue=v;
			},
			// 获取VALUE数据
			getValueList()
			{
				selectContentListById(
				{
				  chnanelType: 2,
				  isIndex: 0,
				  moduleType: 'MODULE_VALUES'
				})
				.then((response)=>
				{
					this.list=response.data.data[0];
				})
			},
		}
	}
</script>

<style scoped>
	.values{
		width: 90%;
		margin-left: 5%;
		margin-top:60px;
	}
	.values-title{
		font-size: 36px;
		color: #505050;
		line-height: 30px;
	}
	.values-message{
		line-height: 30px;
		margin-top: 20px;
		display: inline-block;
		text-align: justify;
		font-size: 26px;
		color: #505050;
		margin-top:76px;
		line-height: 40px;
	}
	.values-message::v-deep img{
		max-width:100%;
	}
</style>